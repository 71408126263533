<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userTicketData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'tickets' }">
          referral List
        </b-link>
      </div>
    </b-alert>

    <template v-if="userTicketData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="12" lg="12" md="12">
          <ticket-view-info
            :user-data="userTicketData"
            :custom-fields="customFields"
          />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BCard,
  BCardText,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import ticketStoreModule from "../ticketStoreModule";
import TicketViewInfo from "./TicketViewInfo.vue";
export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    TicketViewInfo,
    VueGoodTable,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  setup() {
    const userTicketData = ref(null);
    const ticket = ref({
      name: "",
      id: "",
    });
    const USER_APP_STORE_MODULE_NAME = "app-tickets";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, ticketStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("app-tickets/fetchTicket", router.currentRoute.params.id)
      .then((response) => {
        userTicketData.value = response.data.data;
        ticket.value.name = userTicketData.value.name;
        ticket.value.id = userTicketData.value.hashid;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userTicketData.value = undefined;
        }
      });
    return {
      userTicketData,
      ticket,
    };
  },
  data() {
    return {
      pageLength: 3,
      ticketChangeFlag: true,
      pageLengthEmployee: 3,
      dir: false,
      isAddNewInterviewSidebarActive: false,
      columns: [
        {
          label: "Interview Title",
          field: "title",
        },
        {
          label: "Start At",
          field: "start_at",
        },
        {
          label: "End At",
          field: "end_at",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      searchTerm: "",
      customFields: [],
    };
  },
  created() {
    this.getCustomFields();
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },
  methods: {
    viewInterview(id) {
      this.$router.push(`/view/interview/${id}`).catch(() => {});
    },
    assignInterview() {
      console.log("assigning interview...");
      this.isAddNewInterviewSidebarActive = true;
    },
    refetchTicketsData() {
      console.log("refetching started..");
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-tickets/getCustomFields")
        .then((res) => {
          self.customFields = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          self.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Oops! Custom Fields Getting Failed",
                icon: "BellIcon",
                variant: "danger",
              },
            });
        });
    },
    containsKey(obj, key) {
      return Object.keys(obj).includes(key);
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style lang="scss" scoped>
.add-btn {
  border: solid 1px;
  width: 70%;
}
.interview-pass {
  color: green;
}
.interview-fail {
  color: indianred;
}
</style>